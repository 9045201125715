import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      id="eboti_mobile__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 271.31 200"
      {...props}
    >
      <defs>
        <clipPath id="eboti_mobile__clip-path">
          <path
            d="M191.18 116.44l-.83-1a75.52 75.52 0 00-24.66-19.79 67.12 67.12 0 00-57.18-1.35 75.78 75.78 0 00-25.6 18.59l-.87.95a2.1 2.1 0 00-.07 2.69l.82 1a75.73 75.73 0 0024.66 19.79 67.12 67.12 0 0057.18 1.35 75.68 75.68 0 0025.61-18.59l.87-1a2.11 2.11 0 00.07-2.69z"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.eboti_mobile__cls-10{fill:none}.eboti_mobile__cls-4{fill:#d9d9d9}.eboti_mobile__cls-5{fill:#199b69}.eboti_mobile__cls-6{fill:#2bb673}.eboti_mobile__cls-10{stroke:#116043;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px}'
          }
        </style>
      </defs>
      <g id="eboti_mobile___1st" data-name="1st">
        <g id="eboti_mobile__website">
          <motion.path
            animate={{
              scaleX: [1, 1.4, 1],
            }}
            transition={{
              duration: 4, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
            id="eboti_mobile__main_shape"
            data-name="main shape"
            d="M43.25 31h184.8v150.85a11 11 0 01-11 11H54.21a11 11 0 01-11-11V31h0z"
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={2}
            fill="#bababa"
          />
          <motion.path
            animate={{
              scaleX: [1, 1.4, 1],
            }}
            transition={{
              duration: 4, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
            id="eboti_mobile__topbar"
            d="M52.71 7.19H218.6a9.46 9.46 0 019.46 9.46V31h0H43.25h0V16.65a9.46 9.46 0 019.46-9.46z"
            fill="#fff"
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <motion.g
            animate={{
              x: [0, 40, 0],
            }}
            transition={{
              duration: 4, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
          >
            <circle
              id="eboti_mobile__dot_1"
              data-name="dot 1"
              className="eboti_mobile__cls-4"
              cx={215.71}
              cy={19.1}
              r={6.29}
            />
            <circle
              id="eboti_mobile__dot_1-2"
              data-name="dot 1"
              className="eboti_mobile__cls-4"
              cx={197.66}
              cy={19.1}
              r={6.29}
            />
            <circle
              id="eboti_mobile__dot_1-3"
              data-name="dot 1"
              className="eboti_mobile__cls-4"
              cx={178.06}
              cy={19.1}
              r={6.29}
            />
          </motion.g>
          <motion.g
            animate={{
              x: [0, -40, 0],
            }}
            transition={{
              duration: 4, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
          >
            <rect
              id="eboti_mobile__web_adress_bar"
              data-name="web adress bar"
              className="eboti_mobile__cls-4"
              x={48.66}
              y={12.81}
              width={94.37}
              height={12.58}
              rx={6.29}
            />
          </motion.g>
        </g>
        <g id="eboti_mobile__Boti_1" data-name="Boti 1">
          <path
            className="eboti_mobile__cls-5"
            d="M161.38 62A24.42 24.42 0 01137 86.47 24.42 24.42 0 01161.38 62z"
          />
          <path
            className="eboti_mobile__cls-6"
            d="M109.72 54.88a31.59 31.59 0 0131.59 31.59 31.59 31.59 0 01-31.59-31.59z"
            transform="rotate(-180 125.51 70.67)"
          />
          <rect
            className="eboti_mobile__cls-6"
            x={119}
            y={120.6}
            width={33.56}
            height={55.93}
            rx={16.78}
          />
          <path
            className="eboti_mobile__cls-5"
            d="M119 153.88v-22.37h33.56v22.37s-6.21 3.23-16.78 3.23-16.78-3.23-16.78-3.23z"
          />
          <path
            id="eboti_mobile__hrad"
            className="eboti_mobile__cls-6"
            d="M201.67 114.47l-6.52-6.88a83.39 83.39 0 00-118.22-2.86l-6.85 6.55a5.45 5.45 0 00-.19 7.68l6.53 6.88a82.93 82.93 0 0058.52 26 84.52 84.52 0 0016.34-1.19 82.86 82.86 0 0043.36-22l6.85-6.55a5.45 5.45 0 00.18-7.63z"
          />
          <g clipPath="url(#eboti_mobile__clip-path)">
            <path
              className="eboti_mobile__cls-5"
              d="M191.18 116.44l-.83-1a75.52 75.52 0 00-24.66-19.79 67.12 67.12 0 00-57.18-1.35 75.78 75.78 0 00-25.6 18.59l-.87.95a2.1 2.1 0 00-.07 2.69l.82 1a75.73 75.73 0 0024.66 19.79 67.12 67.12 0 0057.18 1.35 75.68 75.68 0 0025.61-18.59l.87-1a2.11 2.11 0 00.07-2.69z"
            />
          </g>
          <path
            d="M190 117.8l-.8-1A74 74 0 00165 97.76a66.69 66.69 0 00-56-1.36 73.83 73.83 0 00-25 17.9l-.85.91a2 2 0 00-.06 2.59l.8 1a74.11 74.11 0 0024.17 19.08 66.69 66.69 0 0056 1.36 74 74 0 0025.05-17.89l.85-.92a2 2 0 00.5-1.28 1.89 1.89 0 00-.46-1.35z"
            fill="#beeda2"
          />
          <path
            d="M134.91 136.78a9.41 9.41 0 01-5.33-1.43 4.56 4.56 0 01-1.93-3 7.26 7.26 0 011.51-5.62 8.2 8.2 0 0114.3 3.36c.27 1.57.2 5.34-6.27 6.45a13.78 13.78 0 01-2.28.24z"
            fill="#116043"
          />
          <motion.g
            animate={{
              x: [0, 30, 0],
            }}
            transition={{
              duration: 4, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
            id="eboti_mobile__hand"
          >
            <path
              className="eboti_mobile__cls-6"
              d="M195.53 134.91h-.05c-6.35 0-11.52 7.39-11.55 16.57 0 9.18 5.09 16.64 11.44 16.66z"
            />
            <path
              className="eboti_mobile__cls-6"
              d="M203.86 161.31c2.37-4.88.12-10.86.12-10.86s-6.09 1.94-8.46 6.83-.11 10.86-.11 10.86 6.08-1.94 8.45-6.83z"
            />
          </motion.g>
          <motion.g
            animate={{
              x: [0, -30, 0],
            }}
            transition={{
              duration: 4, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
            id="eboti_mobile__hand-2"
            data-name="hand"
          >
            <path
              className="eboti_mobile__cls-6"
              d="M74.75 133.39c6.35 0 11.52 7.39 11.55 16.57 0 9.18-5.09 16.64-11.44 16.66z"
            />
            <path
              className="eboti_mobile__cls-6"
              d="M66.42 159.79c-2.37-4.88-.12-10.86-.12-10.86s6.09 1.94 8.46 6.83.11 10.86.11 10.86-6.08-1.94-8.45-6.83z"
            />
          </motion.g>
          <motion.path
            animate={{
              scale: [1, 0, 1],
            }}
            transition={{
              duration: 0.3, repeat: Infinity, repeatDelay: 4,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
            className="eboti_mobile__cls-10"
            d="M159.76 110.79v7.75"
          />
          <motion.path
            animate={{
              scale: [1, 0, 1],
            }}
            transition={{
              duration: 0.3, repeat: Infinity, repeatDelay: 4,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
            className="eboti_mobile__cls-10"
            d="M113.16 110.79v7.75"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
