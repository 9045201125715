import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Card, Button,
} from 'react-bootstrap';
import { Link, navigate } from 'gatsby';

import {
  motion, AnimatePresence, useMotionValue,
} from 'framer-motion';
import parse from 'html-react-parser';
import logo from '../../assets/images/logo.svg';

import './nav.scss';

const Nav = ({
  navOpen, close, mainNavItems, secondaryNavItems, handleShowPreview, showPreview,
}) => {
  const [chapterLink, setChapterLink] = useState(false);
  const [chapterLabel, setChapterLabel] = useState(false);
  const [chapterText, setChapterText] = useState(false);
  const [chapterButtonText, setChapterButtonText] = useState(false);
  const [chapterTheme, setChapterTheme] = useState(false);
  const variants = {
    slideIn: { y: '0vh', transition: { duration: 1 } },
    slideOut: { y: '-100vh', transition: { duration: 1 } },
    fadeOut: { opacity: 0, transition: { duration: 2 } },
  };
  const handleSetPreviewImgAndLink = (link, lable, text, buttonText, theme) => {
    setChapterLink(link);
    setChapterLabel(lable);
    setChapterText(text);
    setChapterButtonText(buttonText);
    setChapterTheme(theme);
    handleShowPreview();
  };

  const handleGoToChapter = (link) => {
    if (link !== window.location.pathname) {
      navigate(link);
    }
    close(false);
  };

  return (
    <AnimatePresence>
      {navOpen && (
      <motion.div
        variants={variants}
        initial={{ y: '-100vh' }}
        animate="slideIn"
        exit={showPreview ? 'fadeOut' : 'slideOut'}
        className={`nav ${navOpen ? 'd-block' : 'd-none'}`}
        style={
          {
            backgroundRepeate: 'no-repeate',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }
        }
      >
        <motion.div
          variants={variants}
          animate={showPreview ? 'slideOut' : 'slideIn'}
          className="nav-inner-wrap"
        >
          <Container className="h-100 d-flex align-items-center">
            <div className="position-absolute nav-logo-container">
              <Link to="/">
                <img src={logo} alt="greenopolis-logo" className="logo" />
              </Link>
            </div>
            <Row className="main-nav-row">
              {mainNavItems ? mainNavItems.map((item) => (
                <Col key={item.id} xs={12}>
                  <Card className={`nav-card-${item.acf_menu_item_fields.chapterTheme}`}>
                    {item.acf_menu_item_fields ? (
                      <Card.Body style={{ display: 'flex' }}>
                        {item.acf_menu_item_fields.thumbnailImage
                          ? (
                            <div className="image-wrap">
                              <Card.Img
                                variant="top"
                                src={item.acf_menu_item_fields.thumbnailImage.localFile
                                  .childImageSharp.gatsbyImageData.images.fallback.src}
                              />
                            </div>
                          )
                          : ''}
                        <div className="content-wrap d-flex justify-content-between w-100 align-items-center">
                          <Card.Title>
                            {item.label}
                          </Card.Title>

                          <div className="d-flex">
                            <Button
                              className="pl-3 btn btn-primary start-btn"
                              onClick={
                              () => handleSetPreviewImgAndLink(
                                item.connectedNode.node.uri,
                                item.label,
                                item.acf_menu_item_fields.text,
                                item.acf_menu_item_fields.openChapterButtonText,
                                item.acf_menu_item_fields.chapterTheme,
                              )
                              }
                            >
                              Start
                              <span>
                                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2 9.29175H18.6667" stroke="#FAFCFF" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M11.375 2L18.6667 9.29167L11.375 16.5833" stroke="#FAFCFF" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </span>
                            </Button>
                            {
                            (item.acf_menu_item_fields.exercisesPageLink)
                              ? (
                                <a
                                  href={item.acf_menu_item_fields.exercisesPageLink.uri}
                                  className="pl-3 btn btn-primary go-to-exercises-btn"
                                  onClick={() => close(false)}
                                >
                                  {item.acf_menu_item_fields.exercisesPageButtonText}
                                </a>
                              ) : ''
                            }
                          </div>
                        </div>
                      </Card.Body>
                    ) : ''}
                  </Card>
                </Col>

              )) : ''}
            </Row>
            <div className="secondary-nav">
              {secondaryNavItems ? secondaryNavItems.map((item, i) => (
                <a
                  className="text-center"
                  key={`item_${i.toString()}`}
                  href={item.connectedNode.node.uri}
                  onClick={() => close(false)}
                >
                  {item.label}
                </a>
              )) : ''}
            </div>
          </Container>
        </motion.div>
        <Container fluid className={`preview-container-${chapterTheme}`}>
          <div className="position-absolute nav-logo-container">
            <Link to="/">
              <img src={logo} alt="greenopolis-logo" className="logo" />
            </Link>
          </div>
          <Row className="w-100 vh-100 justify-content-end align-items-center">
            <Col xs={6} className="h-100">
              <div className="preview-img" />
            </Col>
            <Col xs={6} className="nav-prview-text-col">
              { chapterLabel && <p className="label-desktop">{chapterLabel}</p> }
              { chapterLabel && <h1>{chapterLabel}</h1> }
              { chapterText && <div>{parse(chapterText)}</div> }

              <Button className="d-flex align-items-center justify-content-around open-chapter-btn" onClick={() => { handleGoToChapter(chapterLink); }}>
                <p className="d-block m-0">
                  {chapterButtonText && chapterButtonText}
                </p>
                <span>
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 9.29175H18.6667" stroke="#FAFCFF" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.375 2L18.6667 9.29167L11.375 16.5833" stroke="#FAFCFF" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </motion.div>
      )}
    </AnimatePresence>
  );
};
export default Nav;
