import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import Header from './Header/Header';
import { ThemeStateContext } from '../context/ThemeContextProvider';
import EbotiMobile from './eBotiStates/EbotiMobile/EbotiMobile';

const Layout = ({ isHomePage, children }) => {
  const { themeName } = useContext(ThemeStateContext);

  return (
    <div data-is-root-path={isHomePage} className={`theme-${themeName}`}>
      <Header />
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 3, ease: 'easeOut' } }}
        exit={{
          opacity: [0.1, 0], transition: { duration: 2 },
        }}
      >
        {children}
      </motion.main>
      <div id="mobile">
        <h3 className="font-weight-bold mb-2">
          Resize your window.
        </h3>
        <p className="text-bold text-white font-weight-bold">
          Greenopolis works on tablet and computer.
        </p>

        <EbotiMobile />
      </div>
      {/*
    <footer>
      ©
      {' '}
      {new Date().getFullYear()}
      , Built with
      {' '}
      <a href="https://www.gatsbyjs.com">Gatsby</a>
      {' '}
      And
      {' '}
      <a href="https://wordpress.org/">WordPress</a>
    </footer> */}
    </div>
  );
};

export default Layout;
