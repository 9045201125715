import React, { useState, useContext } from 'react';
import {
  Container, Row, Col, Button, DropdownButton, Dropdown,
} from 'react-bootstrap';
import { Link, useStaticQuery, graphql } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import LogRocket from 'logrocket';
import ReactGA from 'react-ga';
import { GatsbyImage } from 'gatsby-plugin-image';

import { GlobalStateContext, GlobalDispatchContext } from '../../context/GlobalContextProvider';
import Nav from '../Nav/Nav';

import logo from '../../assets/images/logo.png';

import './header.scss';

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const dispatch = useContext(GlobalDispatchContext);
  const { lang, translationLinks } = useContext(GlobalStateContext);

  const TRACKING_ID = 'UA-217481006-1';

  const {
    wp: {
      generalSettings: { title },
    },
    allWpMenu: {
      edges,
    },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      wp {
        generalSettings {
          title
          description
        },
      },
      allWpMenu {
        edges {
          node {
            slug
            name
            cookie_banner_text {
              cookieBannerText
              linkLabel
              link {
                ... on WpPage {
                uri
                }
              }
            }
            menuItems {
              nodes {
                id
                acf_menu_item_fields {
                  text
                  title
                  chapterTheme
                  thumbnailImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 200)
                      }
                    }
                  }
                  exercisesPageButtonText
                  exercisesPageLink {
                    ... on WpBiodiversityExercisesPost {
                      uri
                    }
                    ... on WpHeatExercisesPost {
                      uri
                    }
                    ... on WpAirExercisesPost {
                      uri
                    }
                    ... on WpNoiseExercisesPost {
                      uri
                    }
                    ... on WpWaterExercisesPost {
                      uri
                    }
                  }
                  openChapterButtonText
                }
                label
                url
                connectedNode {
                  node {
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const MainNavigation = edges.find((menu) => menu.node.name === `Main header ${lang.locale ? lang.locale : 'en'}`);

  const SecondaryNavigation = edges.find((menu) => menu.node.name === `Secondary menu ${lang.locale ? lang.locale : 'en'}`);

  const { cookieBannerText } = MainNavigation.node.cookie_banner_text;
  const { linkLabel } = MainNavigation.node.cookie_banner_text;
  const { link } = MainNavigation.node.cookie_banner_text;

  const handleToggleNav = (closeTheNav) => {
    if (!showPreview) {
      setNavOpen(!navOpen);
    } else if (showPreview && !closeTheNav) {
      setNavOpen(false);
      setShowPreview(!showPreview);
    } else {
      setNavOpen(true);
      setShowPreview(!showPreview);
    }
  };

  const handleShowPreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <header>
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={10}>
            <Link className="logo-link" to="/">
              <img src={logo} alt="greenopolis-logo" className="logo" />
            </Link>
          </Col>
          <Col xs={1} className="d-flex justify-content-end">
            <DropdownButton variant="custom" id="dropdown-basic-button" className="language-picker" title={lang.name ? lang.name : ''}>
              {translationLinks.map((item, i) => (
                <Dropdown.Item key={`item_${i.toString()}`} className="ml-2" onClick={() => dispatch({ type: 'SET_LANG', payload: { lang: `${item.language.locale}` } })} href={`${item.uri}`}>{item.language.name}</Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
          <Col xs={1}>
            <Button className={`toggle-nav-button ${navOpen && !showPreview ? 'is-open' : ''}`} onClick={handleToggleNav}>
              <div className="bar" />
              <div className="bar" />
              <div className="bar" />
            </Button>
          </Col>
        </Row>
        {
          MainNavigation || SecondaryNavigation
            ? (
              <Nav
                navOpen={navOpen}
                mainNavItems={MainNavigation ? MainNavigation.node.menuItems.nodes : ''}
                secondaryNavItems={SecondaryNavigation ? SecondaryNavigation.node.menuItems.nodes : ''}
                close={handleToggleNav}
                handleShowPreview={handleShowPreview}
                showPreview={showPreview}
              />
            ) : ''
        }
      </Container>
      {cookieBannerText && link && linkLabel
        ? (
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="CookieConsent"
            expires={150}
            enableDeclineButton
            onAccept={() => {
              LogRocket.init('9uwtfc/green-project');
              ReactGA.initialize(TRACKING_ID);
              ReactGA.pageview(window.location.pathname + window.location.search);
            }}
          >
            {cookieBannerText || ''}
            {'ㅤ'}
            <a href={link ? link.uri : ''}>
              {linkLabel || ''}
            </a>

          </CookieConsent>
        )
        : ''}
    </header>
  );
};

export default Header;
